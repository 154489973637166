const siteMetadata = {
    title: `Hypercube Studio`,
    siteUrl: `http://hypercube.studio`,
    capitalizeTitleOnHome: false,
    logo: `/images/logo_round.svg`,
    icon: `/images/logo_round.svg`,
    titleImage: `/images/Hc.png`,
    ogImage: `/images/Hc.png`,
    twoColumnWall: true,
    cookiePolicy: true,
    introTag: `ROBOT ARM | ARTIST`,
    description: `Lovingly created art...from a robot!`,
    about:
        "Hypercube Studio combines art and technology through modern robotics. Exploring what art means, and what it means to be an artist. The Studio specializes in (robot) hand drawn prints and computer generated art. We offer a wide array of custom drawings.",
    author: `@_alizah`,
    blogItemsPerPage: 10,
    shopItemsPerPage: 10,
    darkmode: true,
    switchTheme: true,
    navLinks: [
        {
            name: "HOME",
            url: "/",
        },
        {
            name: "ABOUT",
            url: "/about",
        },
        {
            name: "BLOG",
            url: "/blog",
        },
        {
            name: "SHOP",
            url: "/shop",
        },
        {
            name: "CONTACT",
            url: "/contact",
        },
    ],
    footerLinks: [
        {
            name: "PRIVACY POLICY",
            url: "/privacy-policy",
        },
        {
            name: "GitHub",
            url: "https://github.com/akzhy/gatsby-starter-elemental",
        },
    ],
    social: [
        {
            name: "Facebook",
            icon: "/images/Facebook.svg",
            url: "#",
        },
        {
            name: "Etsy",
            icon: "/images/Etsy.png",
            url: "https://www.etsy.com/shop/HypercubeStudio",
        },
        {
            name: "Instagram",
            icon: "/images/Instagram.svg",
            url: "https://www.instagram.com/hypercube.studio/",
        },
        {
            name: "TikTok",
            icon: "/images/Tiktok.png",
            url: "https://www.tiktok.com/@hypercube.studio?",
        },
    ],
    contact: {
        // leave empty ('') or false to hide form
        api_url: "https://getform.io/f/f227a36e-096a-4c6a-9963-9f1918a85bb3",
        description: `Drop us an email for custom orders`,
        mail: "hello@hypercube.studio",
        phone: "214-566-8044",
        address: "Financial District \n New York",
    },
    disqus: "elemental-netlify-com",
}

const beforeContactFormSubmit = data => {
    // Code 0 - success
    // Code 1 - Name
    // Code 2 - Email
    // Code 3 - Message
    // Code 4 - Other
    const errors = []

    if (data.name.trim().length < 2) {
        errors.push({
            code: 1,
            message: "Enter a name",
        })
    }

    if (!data.email.match(/[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/)) {
        errors.push({
            code: 2,
            message: "Enter a valid email address",
        })
    }

    if (data.message.trim().length < 15) {
        errors.push({
            code: 3,
            message: "Enter a message with atleast 15 characters",
        })
    }

    if (errors.length > 0)
        return {
            result: false,
            errors: errors,
        }

    return {
        data: {
            name: data.name,
            email: data.email,
            message: data.message,
        },
        result: true,
    }
}

const contactFormSubmit = async (api, data) => {
    let res: any = await fetch(api, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })

    res = await res.json()

    if (res.success) {
        return {
            result: true,
        }
    }
    return {
        result: false,
        ...res,
    }
}

const defaults = {
    disqus: null,
    twoColumnWall: true,
    darkmode: false,
    switchTheme: true,
    capitalizeTitleOnHome: true,
    cookiePolicy: false,
}

Object.keys(defaults).forEach(item => {
    if (siteMetadata[item] === undefined) {
        siteMetadata[item] = defaults[item]
    }
})

export { siteMetadata, beforeContactFormSubmit, contactFormSubmit }
